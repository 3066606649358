import Header from "../components/Header";
import { Container } from "react-bootstrap";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { auth } from "../components/Firebase";

export default function MyAccount({ admin, user, cart }) {
  const history = useHistory();

  useEffect(() => {
    if (user === null) history.push("/");
  }, [user, history]);

  return (
    <div>
      <Header currentPage="Home" user={user} cart={cart} />
      <Container>
        <div>My Account</div>
        <div onClick={() => auth.signOut()}>Log out</div>
        {user ? (
          <div>
            <div>Email: {user.email}</div>
          </div>
        ) : (
          ""
        )}
        {admin ? <div>You are an admin</div> : ""}
      </Container>
    </div>
  );
}
