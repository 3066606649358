import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyC1mWrhQuwQHDFPCtNtqIURWIL33CEACyM",
  authDomain: "travelpakrx-ca831.firebaseapp.com",
  projectId: "travelpakrx-ca831",
  storageBucket: "travelpakrx-ca831.appspot.com",
  messagingSenderId: "881986877030",
  appId: "1:881986877030:web:0fd57b5bfd6dd1fcac1fa7",
  measurementId: "G-VNHSG171GF",
};

firebase.initializeApp(firebaseConfig);
export const auth = firebase.auth();
export const database = firebase.database();
export const firestore = firebase.firestore();
export const config = firebaseConfig;
