import { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { auth } from "./components/Firebase";
import Home from "./pages/Home";
import MyAccount from "./pages/MyAccount";
import Cart from "./pages/Cart";
import Checkout from "./pages/Checkout";
import Product from "./pages/Product";
import PageNotFound from "./pages/PageNotFound";
import ScrollToTop from "./components/ScrollToTop";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

function App() {
  const [cart, setCart] = useState([]);
  const [user, setUser] = useState(false);
  const [admin, setAdmin] = useState(false);

  useEffect(() => {
    auth.onAuthStateChanged((changedUserData) => {
      if (changedUserData) changedUserData.getIdTokenResult().then((idTokenResult) => setAdmin(!!idTokenResult.claims.admin));
      setUser(changedUserData);
    });
    const localCart = JSON.parse(localStorage.getItem("cart"));
    if (localCart) setCart(localCart);
  }, []);

  const addItem = (item, callback) => {
    const cartCopy = [...cart];
    const existingItem = cartCopy.find((cartItem) => cartItem.id === item.id);
    if (existingItem) existingItem.quantity += item.quantity;
    else cartCopy.push({ ...item, quantity: item.quantity });
    setCart(cartCopy);
    const stringCart = JSON.stringify(cartCopy);
    localStorage.setItem("cart", stringCart);
    callback();
  };

  const removeItem = (item) => {
    const newCart = cart.filter((cartItem) => cartItem.id === item.id);
    setCart(newCart);
    const stringCart = JSON.stringify(newCart);
    localStorage.setItem("cart", stringCart);
  };

  const changeQuantity = (item, quantity) => {
    if (quantity) {
      const cartCopy = [...cart];
      const existingItem = cartCopy.find((cartItem) => cartItem.id === item.id);
      existingItem.quantity = quantity;
      setCart(cartCopy);
      const stringCart = JSON.stringify(cartCopy);
      localStorage.setItem("cart", stringCart);
    }
  };

  return (
    <Router>
      <ScrollToTop />
      <Switch>
        <Route exact path="/product/:productName">
          <Product user={user} cart={cart} addItem={addItem} />
        </Route>
        <Route exact path="/cart">
          <Cart user={user} cart={cart} removeItem={removeItem} changeQuantity={changeQuantity} />
        </Route>
        <Route exact path="/checkout">
          <Checkout user={user} cart={cart} />
        </Route>
        <Route exact path="/my-account">
          <MyAccount admin={admin} user={user} cart={cart} />
        </Route>
        <Route exact path="/">
          <Home user={user} cart={cart} />
        </Route>
        <Route>
          <PageNotFound user={user} cart={cart} />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
