import Header from "../components/Header";
import { Container, Col, Row, Image } from "react-bootstrap";
import Kit1 from "../components/kit1.webp";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";

export default function Cart({ user, cart, removeItem, changeQuantity }) {
  const [subtotal, setSubtotal] = useState(0);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    let subtotalLocal = 0;
    let totalLocal = 0;
    cart.forEach((item) => {
      subtotalLocal = subtotalLocal + Math.round(item.price * item.quantity * 100) / 100;
      totalLocal = totalLocal + Math.round((item.price + item.price * 0.0825) * item.quantity * 100) / 100;
    });
    setSubtotal(subtotalLocal);
    setTotal(totalLocal);
  }, [cart]);

  const history = useHistory();

  return (
    <div>
      <Header currentPage="Cart" user={user} cart={cart} />
      <Container>
        <Row className="cartTitle">Cart</Row>
        {!cart || !Array.isArray(cart) || cart.length === 0 ? (
          <div className="noItems">There are no items in your cart</div>
        ) : (
          <div>
            {cart.map((item, index) => {
              return (
                <div key={index} className="cartItem">
                  <div className="cartItemLeftSide">
                    <Image src={Kit1} className="cartImage" />
                    <div>
                      <div>{item.name}</div>
                      <div>${item.price}</div>
                    </div>
                  </div>
                  <div className="cartItemRightSide">
                    <div className="quanityValue">
                      <div className="quantityChange" onClick={() => changeQuantity(item, item.quantity - 1)}>
                        -
                      </div>
                      <div>{item.quantity}</div>
                      <div className="quantityChange" onClick={() => changeQuantity(item, item.quantity + 1)}>
                        +
                      </div>
                    </div>
                    <div onClick={removeItem}>Remove</div>
                  </div>
                </div>
              );
            })}
            <div className="cartGoToCheckout">
              <div>Estimated Delivery Date: {new Date(Date.now() + 6.048e8 * 1.5).toLocaleDateString()}</div>
              <div>Subtotal: {subtotal.toFixed(2)}</div>
              <div>Total: {total.toFixed(2)}</div>
              <div onClick={() => history.push("/checkout")} className="cartGoToCheckoutButton">
                Checkout
              </div>
            </div>
          </div>
        )}
      </Container>
    </div>
  );
}
