import Header from "../components/Header";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { useStripe, useElements, Elements, CardElement } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe("pk_live_2ckeZts6pKiKkrkoEWJE0Cf8");
const testAPI = "http://localhost:5001/travelpakrx-ca831/us-central1/api/api/payment-secret";

export default function Checkout({ user, cart }) {
  const [clientSecret, setClientSecret] = useState(null);
  const [totalAmount, setTotalAmount] = useState(0);

  useEffect(() => {
    if (Array.isArray(cart) && cart.length !== 0) {
      let amount = 0;
      cart.forEach((item) => {
        amount = amount + Math.round((item.price + item.price * 0.0825) * item.quantity * 100) / 100;
      });
      setTotalAmount(amount);
      fetch(testAPI, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ amount }),
      })
        .then((response) => response.json())
        .then((response) => setClientSecret(response.clientSecret))
        .catch(() => setClientSecret(false));
    }
  }, [cart]);

  return (
    <div>
      <Header currentPage="Home" user={user} cart={cart} />
      <Container>
        <div>Checkout</div>
        <Link to={{ pathname: "/cart" }}>Return to cart</Link>
        {!cart || !Array.isArray(cart) || cart.length === 0 ? (
          <div>No items in your cart</div>
        ) : (
          <Elements stripe={stripePromise}>
            <CheckoutForm cart={cart} totalAmount={totalAmount} clientSecret={clientSecret} />
          </Elements>
        )}
      </Container>
    </div>
  );
}

function CheckoutForm({ cart, totalAmount, clientSecret }) {
  const [shippingInfo, setShippingInfo] = useState({ fullName: "", phoneNumber: "", streetAddress: "", streetAddress2: "" });
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) return;

    const result = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: "Jenny Rosen",
        },
      },
    });

    if (result.error) console.log(result.error.message);
    else if (result.paymentIntent.status === "succeeded") console.log("Payment Successful");
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>Shipping Info:</div>
      <div>Full name (First and Last name)</div>
      <input
        type="text"
        placeholder=""
        value={shippingInfo.fullName}
        onChange={(e) => setShippingInfo({ ...shippingInfo, fullName: e.target.value })}
      />
      <div>Phone number</div>
      <input
        type="text"
        placeholder=""
        value={shippingInfo.phoneNumber}
        onChange={(e) => setShippingInfo({ ...shippingInfo, phoneNumber: e.target.value })}
      />
      <div>Address</div>
      <input
        type="text"
        placeholder="Street address or P.O. Box"
        value={shippingInfo.streetAddress}
        onChange={(e) => setShippingInfo({ ...shippingInfo, streetAddress: e.target.value })}
      />
      <input
        type="text"
        placeholder="Apt, suite, unit, building, floor, etc."
        value={shippingInfo.streetAddress2}
        onChange={(e) => setShippingInfo({ ...shippingInfo, streetAddress2: e.target.value })}
      />
      <div>
        Card details
        <CardElement />
      </div>
      {totalAmount}
      <input type="submit" value="Submit Payment" />
    </form>
  );
}
