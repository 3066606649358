import { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Container, Col, Row, Image } from "react-bootstrap";
import Kit1 from "../components/kit1.webp";
import { firestore } from "../components/Firebase";
import Header from "../components/Header";

export default function Product({ user, cart, addItem }) {
  const { productName } = useParams();
  const history = useHistory();
  const [product, setProduct] = useState(null);
  const [quantity, setQuantity] = useState(1);

  useEffect(() => {
    if (productName) {
      firestore
        .collection("products")
        .doc(productName.toLowerCase())
        .get()
        .then((data) => data.data())
        .then((data) => (!data ? setProduct(false) : setProduct(data)));
    }
  }, [productName]);

  return (
    <Container>
      <Header currentPage="Product" user={user} cart={cart} />
      {product === null ? (
        <div>Loading</div>
      ) : !product ? (
        <div>Product does not exist</div>
      ) : (
        <Row className="productPage">
          <Col>
            <Image src={Kit1} className="homePageHeaderSectionImage" />
          </Col>
          <Col>
            <div className="productName">{product.name}</div>
            <div className="productPrice">${product.price}</div>
            <div className="productDescription">
              {product.description.map((description, index) => {
                return <div key={index}>{description}</div>;
              })}
            </div>
            <div className="productCart">
              <div>
                <div className="quanityValue">
                  <div className="quantityChange" onClick={() => setQuantity(quantity - 1 ? quantity - 1 : 1)}>
                    -
                  </div>
                  <div>{quantity}</div>
                  <div className="quantityChange" onClick={() => setQuantity(quantity + 1)}>
                    +
                  </div>
                </div>
              </div>
              {!product.stock ? (
                <div className="outOfStock">Out of stock</div>
              ) : (
                <div className="addToCart" onClick={() => addItem({ ...product, quantity }, () => history.push("/cart"))}>
                  Add to cart
                </div>
              )}
            </div>
          </Col>
        </Row>
      )}
    </Container>
  );
}
