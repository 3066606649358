import { useHistory } from "react-router-dom";
import Header from "../components/Header";
import { Container, Carousel, Col, Row, Image, Button } from "react-bootstrap";
import CarouselBackground from "../components/carousel-background.webp";
import Kit1 from "../components/kit1.webp";

export default function Home({ user, cart }) {
  const history = useHistory();

  return (
    <div>
      <Header currentPage="Home" user={user} cart={cart} />
      <Carousel controls={false} indicators={false}>
        <Carousel.Item>
          <img className="d-block w-100" src={CarouselBackground} alt="First slide" />
        </Carousel.Item>
      </Carousel>
      <Container>
        <Row className="homePageHeaderSection">
          <Col>
            <Image src={Kit1} className="homePageHeaderSectionImage" />
          </Col>
          <Col className="homePageHeaderSectionText">
            <Row className="homePageHeaderTitle">A Complete Travel Size Medicine Kit</Row>
            <Row>The first reputable First-Aid kit of over-the-counter medicine, created by your friendly, neighborhood pharmacist.</Row>
            <Row>
              This conveniently sized box contains 73 tablets of 6 different medications that are vital to your travel needs to keep you healthy.
            </Row>
            <Row>
              Travel size medicine packs are perfect for traveling on the go. The medication comes in individually wrapped unit dose for easy access
              while camping, hiking, cruises, road trips, or any other vacation.
            </Row>
            <Row>
              No need to carry around multiple medicine bottles. Each kit contains just the right amount of travel meds. Perfect size to fit in a
              suitcase for business travelers.
            </Row>
            <Row>
              <Button variant="primary" size="md" onClick={() => history.push("/product/Complete-Travel-Size-Medicine-Kit")}>
                BUY NOW
              </Button>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
