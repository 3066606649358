import Header from "../components/Header";
import { Container } from "react-bootstrap";

export default function PageNotFound({ user, cart }) {
  return (
    <div>
      <Header currentPage="PageNotFound" user={user} cart={cart} />
      <Container>
        <div>Page Not Found</div>
      </Container>
    </div>
  );
}
