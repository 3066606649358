import { Container, Navbar, Nav, Button, Modal } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { VscAccount } from "react-icons/vsc";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { useState } from "react";
import { auth } from "./Firebase";

export default function Header({ user, currentPage, cart }) {
  const [loginModal, setLoginModal] = useState(false);
  const [showRegister, setShowRegister] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [loginError, setLoginError] = useState(false);
  const [registerError, setRegisterError] = useState(false);

  const history = useHistory();

  return (
    <div>
      <Navbar collapseOnSelect expand="lg">
        <Container>
          <Link className="logoText" to={{ pathname: "/" }}>
            TravelPakRx
          </Link>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mr-auto"></Nav>
            <Nav>
              <Button
                active={currentPage === "TravelPack"}
                variant="primary"
                size="md"
                onClick={() => history.push("/product/Complete-Travel-Size-Medicine-Kit")}
              >
                BUY DIRECTLY
              </Button>
              <Button
                className="buyOnAmazon"
                variant="primary"
                size="md"
                onClick={() =>
                  (window.location.href = "https://www.amazon.com/TravelPakRx-Different-Medications-Dramamine-Electrolytes/dp/B07BBV2H7N")
                }
              >
                BUY ON AMAZON
              </Button>
              <Nav.Link className="headerIcon" active={currentPage === "Cart"} onClick={() => history.push("/cart")}>
                <AiOutlineShoppingCart className="cartHeaderIcon" /> <div className="cartHeaderIconText">{Array.isArray(cart) ? cart.length : 0}</div>
              </Nav.Link>
              <Nav.Link active={currentPage === "MyAccount"} onClick={() => (user ? history.push("/my-account") : setLoginModal(true))}>
                <VscAccount className="accountHeaderIcon" />
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
          <Modal show={loginModal} onHide={() => setLoginModal(false)} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            {showRegister ? (
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  auth
                    .createUserWithEmailAndPassword(email, password)
                    .then(() => setLoginModal(false))
                    .catch((error) => {
                      if (error.code === "auth/email-already-in-use") setRegisterError("An account with this E-mail already exists.");
                      else setRegisterError(error.message);
                    });
                }}
              >
                <Modal.Header>
                  <Modal.Title id="contained-modal-title-vcenter">Register</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                  <div>
                    <div>Email</div>
                    <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                  </div>
                  <div>
                    <div>Password</div>
                    <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                  </div>
                  {registerError ? <div>{registerError}</div> : ""}
                </Modal.Body>
                <Modal.Footer>
                  <div className="modalAlt" onClick={() => setShowRegister(false)}>
                    Already have an account?
                  </div>
                  <Button className="closeButton" onClick={() => setLoginModal(false)}>
                    Close
                  </Button>
                  <Button type="submit">Register</Button>
                </Modal.Footer>
              </form>
            ) : (
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  auth
                    .signInWithEmailAndPassword(email, password)
                    .then(() => setLoginModal(false))
                    .catch(() => {
                      setLoginError("Incorrect email/password");
                    });
                }}
              >
                <Modal.Header>
                  <Modal.Title id="contained-modal-title-vcenter">Login</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                  <div>
                    <div>Email</div>
                    <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                  </div>
                  <div>
                    <div>Password</div>
                    <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                  </div>
                  {loginError ? <div>{loginError}</div> : ""}
                </Modal.Body>
                <Modal.Footer>
                  <div className="modalAlt" onClick={() => setShowRegister(true)}>
                    Don't have an account?
                  </div>
                  <Button className="closeButton" onClick={() => setLoginModal(false)}>
                    Close
                  </Button>
                  <Button type="submit">Login</Button>
                </Modal.Footer>
              </form>
            )}
          </Modal>
        </Container>
      </Navbar>
    </div>
  );
}
